var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.topic
    ? _c(
        "div",
        { staticClass: "flex" },
        [
          _c("icon-component", {
            staticClass: "mr-2",
            attrs: { name: "flag", height: 16, width: 16 },
          }),
          _vm._v(_vm._s(_vm.topic) + " "),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }