/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import Routes from '@/router/Routes';

export const linkToApplicantProfilePageCallback = (
  cellDate: any,
  rowData: BaseCandidate
): any => {
  return {
    linkTo: {
      name: Routes.APPLICANT_DETAILS,
      params: { id: String(rowData.id) }
    }
  };
};
