
import { Log } from '@/models/Entities/Log';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<ActionCompletedButtonComponent>({})
export default class ActionCompletedButtonComponent extends Vue {
  @Prop()
  readonly log!: Log;

  private completed = false;

  get currentUserDisplayName(): string {
    return this.$store.getters['userModule/currentUser'].displayName;
  }

  get logCompletionStatus() {
    return this.log.completed || this.completed;
  }

  markCompleted() {
    this.completed = true;
    this.$store.dispatch('applicantModule/patchApplicantLog', {
      logId: this.log.id,
      payload: {
        actionRequired: false,
        completed: true,
        completedBy: this.currentUserDisplayName,
        completedDateTime: new Date()
      }
    });
  }
}
