import { render, staticRenderFns } from "./StudentImmediateActionPage.vue?vue&type=template&id=b6a3fa82&"
import script from "./StudentImmediateActionPage.vue?vue&type=script&lang=ts&"
export * from "./StudentImmediateActionPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspace/src/services/webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b6a3fa82')) {
      api.createRecord('b6a3fa82', component.options)
    } else {
      api.reload('b6a3fa82', component.options)
    }
    module.hot.accept("./StudentImmediateActionPage.vue?vue&type=template&id=b6a3fa82&", function () {
      api.rerender('b6a3fa82', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/340th/Student/StudentImmediateActionPage.vue"
export default component.exports