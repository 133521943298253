var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "p-4 bg-gray-100" }, [
    _c(
      "div",
      { staticClass: "flex items-center mb-4" },
      [_c("bread-crumb-component", { attrs: { links: _vm.links } })],
      1
    ),
    _c("div", [
      _c(
        "div",
        { staticClass: "p-4 bg-white border rounded shadow" },
        [
          _c(
            "legacy-table-component",
            _vm._g(
              {
                attrs: {
                  data: _vm.filteredTableData,
                  isSelectable: true,
                  isFilterable: true,
                  isSortable: true,
                  isColumnEditable: true,
                  paginationRows: 8,
                  compact: true,
                  tableConfigurations: _vm.tableConfigurations,
                  initialColumnOrder: _vm.initialColumnOrder,
                  localStorageKey: `340th-table-${_vm.title}`,
                },
              },
              _vm.$listeners
            ),
            [
              _c("template", { slot: "settings-left" }, [
                _c("div", { staticClass: "w-full" }, [
                  _c("div", { staticClass: "font-semibold whitespace-nowrap" }),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }