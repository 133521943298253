
import { Component, Prop, Vue } from 'vue-property-decorator';
import Routes from '@/router/Routes';

export type BreadCrumbLink = {
  label: string;
  route?: Routes;
};

@Component<BreadCrumbComponent>({})
export default class BreadCrumbComponent extends Vue {
  @Prop()
  links!: BreadCrumbLink[];

  lastElement(index: number): boolean {
    return index === this.links.length - 1;
  }
}
