import { TableConfigurations } from './LegacyTable';
import DateTableElementComponent from '@/components/Table/Elements/DateTableElementComponent.vue';
import DateTimeTableElementComponent from '@/components/Table/Elements/DateTimeTableElementComponent.vue';

export const tableConfigurations = {
  dateOfBirth: {
    component: DateTableElementComponent,
    type: 'date'
  },
  priorServiceCommissionServiceDate: {
    component: DateTableElementComponent,
    type: 'date'
  },
  academicGraduationDate: {
    component: DateTableElementComponent,
    type: 'date'
  },
  testingAfoqtDate: {
    component: DateTableElementComponent,
    type: 'date'
  },
  createDateTime: {
    component: DateTimeTableElementComponent,
    type: 'date',
    columnTitle: 'Created Date'
  },
  lastChangedDateTime: {
    component: DateTimeTableElementComponent,
    type: 'date'
  },
  vitalsCommCheckDate: {
    component: DateTableElementComponent,
    type: 'date'
  },
  medicalClearanceDate: {
    component: DateTableElementComponent,
    type: 'date'
  },
  securityClearanceDate: {
    component: DateTableElementComponent,
    type: 'date'
  }
} as TableConfigurations;
