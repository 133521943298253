import { ColumnOrder, TableConfiguration } from '../LegacyTable';

/**
 * generates a ColumnOrder configuration using the keys from the table configuration
 *
 * WARNING: "id" is set to be stripped out by default
 *
 * @export
 * @param {TableConfiguration} configuration the table configuration
 * @param {string[]} [pinnedColumns=[]] the columns you want to pin (these get excluded from the unpinned section)
 * @param {string[]} [excludedColumns=[]] the columns you want to exclude
 * @returns  {ColumnOrder}
 */
export default function generateColumnOrder(
  configuration: TableConfiguration,
  pinnedColumns: string[] = [],
  excludedColumns: string[] = []
): ColumnOrder {
  const columnsToExclude = ['id', ...pinnedColumns, ...excludedColumns];
  return {
    pinned: pinnedColumns,
    unpinned: Object.keys(configuration).filter(
      (item) => !columnsToExclude.includes(item)
    )
  };
}
