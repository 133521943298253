/* eslint-disable @typescript-eslint/no-explicit-any */
import ActionCompletedButtonComponent from '@/components/Table/Elements/ActionCompletedButtonComponent.vue';
import ActionTypeComponent from '@/components/Table/Elements/ActionTypeComponent.vue';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import { tableConfigurations } from '@/models/Table/Legacy/LegacyTableConfiguration';
import {
  ColumnOrder,
  TableConfigurations,
  TableFilterItem,
  TableFilterTypes
} from '@/models/Table/Legacy/LegacyTable';
import { linkToApplicantProfilePageCallback } from '@/util/linking/linkTo';
import generateColumnOrder from '../../Legacy/Util/LegacygenerateColumnOrder';

const configs: TableConfigurations = {
  lastName: {
    component: {
      componentRef: LinkElementComponent,
      componentProps: linkToApplicantProfilePageCallback
    },
    filterItem: new TableFilterItem({
      label: 'Last Name',
      column: 'lastName',
      filterType: TableFilterTypes.STRING
    })
  },
  firstName: {
    type: 'string'
  },
  note: {
    type: 'string'
  },
  topic: {
    type: 'string',
    columnTitle: 'Action Type',
    component: {
      componentRef: ActionTypeComponent,
      componentProps: (_cellData, student: any) => {
        return {
          topic: student.topic
        };
      }
    }
  },
  poc: {
    type: 'string',
    columnTitle: 'Person of Contact'
  },
  date: {
    type: 'string',
    columnTitle: 'Date Created'
  },
  markCompleted: {
    type: 'string',
    component: {
      componentRef: ActionCompletedButtonComponent,
      componentProps: (_cellData, rowData: any) => {
        return {
          log: rowData.log,
          currentUserDisplayName: rowData.currentUserDisplayName
        };
      }
    }
  }
};

export const ImmediateActionTableConfig = {
  ...tableConfigurations,
  ...configs
};

export const ImmediateActionColumnOrderConfig: ColumnOrder =
  generateColumnOrder(ImmediateActionTableConfig);
