
import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatDateTime } from '@/services/formatService';

@Component<DateTimeTableElementComponent>({})
export default class DateTimeTableElementComponent extends Vue {
  @Prop()
  value?: string | Date;

  private format = formatDateTime;
}
