
import { TableConfigurations } from '@/models/Table/Legacy/LegacyTable';
import LegacyTableComponent from '@/models/Table/Legacy/LegacyTableComponent.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Routes from '@/router/Routes';
import BreadCrumbComponent, {
  BreadCrumbLink
} from '@/components/BreadCrumbComponent.vue';
import { sortDatesDescending } from '@/util/date';
import { Log } from '@/models/Entities/Log';
import { Student } from '@/models/Entities/Student/Student';
import {
  ImmediateActionTableConfig,
  ImmediateActionColumnOrderConfig
} from '@/models/Table/Configurations/StudentTables/ImmediateActionConfiguration';

class TableViewModel {
  id!: number;
  firstName?: string;
  lastName?: string;
  topic?: string;
  poc!: string;
  date!: Date;
  note!: string;
  markCompleted!: boolean;
  log!: Log;
  currentUserDisplayName!: string;

  constructor(immediateActionStudent: any) {
    this.id = immediateActionStudent.id;
    this.firstName =
      immediateActionStudent.student.identificationInformationFirstname;
    this.lastName =
      immediateActionStudent.student.identificationInformationLastname;
    this.topic = immediateActionStudent.actionType;
    this.poc = immediateActionStudent.createdBy;
    this.date = immediateActionStudent.dateCreated;
    this.note = immediateActionStudent.note;
    this.log = immediateActionStudent.log;
    this.currentUserDisplayName = immediateActionStudent.currentUserDisplayName;
  }
}

@Component<StudentImmediateActionPage>({
  components: {
    LegacyTableComponent,
    BreadCrumbComponent
  }
})
export default class StudentImmediateActionPage extends Vue {
  @Prop({ default: () => [] })
  students!: Student[];

  @Prop({ default: 'Immediate Action' })
  title!: string;

  tableConfigurations: TableConfigurations = ImmediateActionTableConfig;

  initialColumnOrder = ImmediateActionColumnOrderConfig;

  get links(): BreadCrumbLink[] {
    return [
      { label: '340th Dashboard', route: Routes.STUDENT_DASHBOARD },
      { label: this.title }
    ];
  }

  get filteredTableData(): TableViewModel[] {
    if (!this.students) return [];
    const updatedStudents = this.students.map((student) => {
      return new TableViewModel(student);
    });
    updatedStudents.sort((studentA, studentB) => {
      return sortDatesDescending(studentA.date, studentB.date);
    });
    return updatedStudents;
  }

  private async toDetailView(): Promise<void> {
    this.$router.push({
      name: Routes.STUDENT_IMMEDIATE_ACTION,
      params: {
        title: this.title,
        students: this.filteredTableData as unknown as string
      }
    });
  }
}
