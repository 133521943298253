var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    !_vm.logCompletionStatus
      ? _c(
          "button",
          {
            staticClass:
              "px-4 py-1 font-bold text-white rounded bg-val-button-blue hover:bg-blue-700 focus:outline-none focus:shadow-outline",
            attrs: { color: "blue", bg: "White" },
            on: { click: _vm.markCompleted },
          },
          [_vm._v(" Mark Done ")]
        )
      : _c("div", [_vm._v("Completed")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }