var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._l(_vm.links, function (link, index) {
      return _c(
        "div",
        { key: index, staticClass: "inline mt-8 mr-2 text-2xl font-semibold" },
        [
          link.route
            ? _c(
                "router-link",
                {
                  staticClass: "underline cursor-pointer",
                  attrs: { to: { name: link.route } },
                },
                [_vm._v(" " + _vm._s(link.label) + " ")]
              )
            : _c("span", [_vm._v(" " + _vm._s(link.label) + " ")]),
          !_vm.lastElement(index)
            ? _c("span", { staticClass: "ml-2" }, [_vm._v("/")])
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }